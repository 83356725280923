<template>
  <div>
    <el-tabs>
      <el-tab-pane :label="$l('userOperateLog.operateLog', '操作日志')">
        <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="userOperateLog" @clear="clearDateRange">
          <template slot="adSearch">
            <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
              <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
            </vm-search>
            <vm-search :label="$l('userOperateLog.operateTime', '操作时间')">
              <el-date-picker v-model="dateRange" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange"
                :unlink-panels="true" :range-separator="$t('common.to')" :start-placeholder="$t('common.startDate')"
                :end-placeholder="$t('common.endDate')" :default-time="['00:00:00', '23:59:59']"
                @change="handleDateChange">
              </el-date-picker>
            </vm-search>
          </template>
          <el-table-column prop="userName" :label="$l('userOperateLog.userName', '用户名')"></el-table-column>
          <el-table-column prop="name" :label="$l('userOperateLog.name', '员工姓名')"></el-table-column>
          <el-table-column prop="operateContent" :label="$l('userOperateLog.operateContent', '操作内容')"></el-table-column>
          <el-table-column prop="operateTime" :label="$l('userOperateLog.operateTime', '操作时间')" align="center"
            width="140"></el-table-column>
        </vm-table>
      </el-tab-pane>
      <el-tab-pane :label="$l('userOperateLog.appOperateLog', 'app操作日志')">
        <vm-table ref="vmTable1" v-loading="loading1" :filter.sync="search1" url="userAppOperateLog"
          @clear="clearDateRange1">
          <template slot="adSearch">
            <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
              <el-input v-model.trim="search1.filter" :placeholder="$t('common.search')" clearable></el-input>
            </vm-search>
            <vm-search :label="$l('userOperateLog.operateTime', '操作时间')">
              <el-date-picker v-model="dateRange1" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange"
                :unlink-panels="true" :range-separator="$t('common.to')" :start-placeholder="$t('common.startDate')"
                :end-placeholder="$t('common.endDate')" :default-time="['00:00:00', '23:59:59']"
                @change="handleDateChange1">
              </el-date-picker>
            </vm-search>
          </template>
          <el-table-column prop="userName" :label="$l('userOperateLog.userName', '用户名')"></el-table-column>
          <el-table-column prop="name" :label="$l('userOperateLog.name', '员工姓名')"></el-table-column>
          <el-table-column prop="operateContent" :label="$l('userOperateLog.operateContent', '操作内容')"></el-table-column>
          <el-table-column prop="operateTime" :label="$l('userOperateLog.operateTime', '操作时间')" align="center"
            width="140"></el-table-column>
        </vm-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  userName: "",
  data() {
    return {
      loading: true,
      loading1: true,
      dateRange: [],
      dateRange1: [],
      search: {
        filter: "",
        startTime: "",
        endTime: "",
      },
      search1: {
        filter: "",
        startTime: "",
        endTime: "",
      },
    };
  },
  mounted() {
    this.getList(1);
    this.getAppList(1);
  },
  methods: {
    getList(pageNum) {
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    getAppList(pageNum) {
      this.loading1 = false;
      this.$refs.vmTable1.getList(pageNum);
    },
    handleDateChange(dateRange) {
      if (dateRange != null) {
        this.search.startTime = dateRange[0];
        this.search.endTime = dateRange[1];
      } else {
        this.search.startTime = "";
        this.search.endTime = "";
      }
    },
    handleDateChange1(dateRange) {
      if (dateRange != null) {
        this.search1.startTime = dateRange[0];
        this.search1.endTime = dateRange[1];
      } else {
        this.search1.startTime = "";
        this.search1.endTime = "";
      }
    },
    clearDateRange() {
      this.dateRange = [];
    },
    clearDateRange1() {
      this.dateRange1 = [];
    },
  },
};
</script>
